import { useRegisterEvents, useSigma } from "@react-sigma/core"
import "@react-sigma/core/lib/react-sigma.min.css"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

interface GraphEventsProps {
  hoveredCard: string | null
  setHoveredCard: Dispatch<SetStateAction<string | null>>
}

export function GraphEvents(props: GraphEventsProps) {
  const { setHoveredCard, hoveredCard } = props
  const registerEvents = useRegisterEvents()
  const sigma = useSigma()
  const [draggedNode, setDraggedNode] = useState<string | null>(null)

  useEffect(() => {
    registerEvents({
      // clickNode: event => {
      //   if (!clickedNode) {
      //     setClickedNode(event.node)
      //     sigma.getGraph().setNodeAttribute(event.node, "highlighted", true)
      //   } else {
      //     setClickedNode(null)
      //     sigma.getGraph().setNodeAttribute(event.node, "highlighted", false)
      //   }
      // },

      // On hover
      enterNode: event => {
        if (draggedNode) return
        if (hoveredCard) return
        const graph = sigma.getGraph()
        graph.forEachNode(node => {
          if (node !== event.node && !graph.neighbors(event.node).includes(node)) {
            graph.setNodeAttribute(node, "color", "rgba(214, 214, 214, 0.88)")
            graph.setNodeAttribute(node, "borderColor", "rgba(214, 214, 214, 0.88)")
          }
        })
        graph.forEachEdge((edge, attributes, source, target) => {
          if (source === event.node || target === event.node) {
            graph.setEdgeAttribute(edge, "color", graph.getNodeAttribute(event.node, "color"))
          } else {
            graph.setEdgeAttribute(edge, "color", "rgba(214, 214, 214, 0.88)")
          }
        })
      },
      leaveNode: () => {
        if (draggedNode) return
        if (hoveredCard) return
        const graph = sigma.getGraph()
        graph.forEachEdge((edge, attributes) => {
          graph.setEdgeAttribute(edge, "color", "#D6D6D6")
        })
        graph.forEachNode(node => {
          graph.setNodeAttribute(node, "size", graph.getNodeAttribute(node, "defaultSize"))
          graph.setNodeAttribute(node, "color", graph.getNodeAttribute(node, "defaultColor"))
          graph.setNodeAttribute(
            node,
            "borderColor",
            graph.getNodeAttribute(node, "defaultBorderColor")
          )
        })
      },

      // Drag'n'Drop
      downNode: e => {
        setDraggedNode(e.node)
        if (e.node !== hoveredCard) {
          sigma.getGraph().setNodeAttribute(e.node, "highlighted", true)
        }
      },
      mousemovebody: e => {
        if (!draggedNode) return
        const pos = sigma.viewportToGraph(e)
        sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x)
        sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y)

        e.preventSigmaDefault()
        e.original.preventDefault()
        e.original.stopPropagation()
      },
      mouseup: () => {
        if (draggedNode) {
          setDraggedNode(null)
          if (draggedNode !== hoveredCard) {
            sigma.getGraph().removeNodeAttribute(draggedNode, "highlighted")
          }
        }
      },
      mousedown: () => {
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox())
      }
    })
  }, [registerEvents, sigma, draggedNode, hoveredCard])

  return null
}

import { SigmaContainer } from "@react-sigma/core"
import { Dispatch, MouseEventHandler, SetStateAction, useMemo } from "react"
import { createPortal } from "react-dom"
import {
  GraphRefType,
  GraphologyGradeCategory,
  GraphologyIdeaGroup,
  LegendStyles
} from "../../../../../../pages/DashboardPage/types/types"
import { Legend } from "../../../../../system/components/Legend/Legend"
import { MapFullscreenGraphTranslates } from "../../translates/translates"
import { GraphEvents } from "../GraphEvents"
import { quadrantLegend } from "../demoConstants"
import { BackIcon } from "./BackIcon"
import styles from "./FullscreemGraphStyle.module.css"

interface FullscreenGraphProps extends GraphRefType {
  gradeCategories: GraphologyGradeCategory[]
  ideaGroups: GraphologyIdeaGroup[]
  fullscreenControl?: MouseEventHandler
  legendStyles: LegendStyles
  translates: MapFullscreenGraphTranslates
  setHoveredCard: Dispatch<SetStateAction<string | null>>
  hoveredCard: string | null
}

export function FullscreenGraph(props: FullscreenGraphProps) {
  const {
    ideaGroups,
    gradeCategories,
    fullscreenControl,
    graph,
    sigmaSettings,
    legendStyles,
    translates,
    setHoveredCard,
    hoveredCard
  } = props

  const graphType = useMemo(() => graph?.getAttribute("type"), [graph])

  // TODO translates
  return createPortal(
    <div className={styles.fullScreenGraph}>
      <div className={styles.fullScreenControls}>
        <div className={styles.fullScreenBack} onClick={fullscreenControl}>
          <BackIcon />
          <p>{translates.back}</p>
        </div>

        <div className={styles.fullScreenGraphLegendBlock}>
          <p>{graphType === "groups" ? translates.groups : translates.grades}</p>
          <div className={styles.fullScreenGraphLegend}>
            <Legend
              items={graphType === "groups" ? ideaGroups : quadrantLegend}
              CSSstyles={legendStyles}
            />
          </div>
        </div>
      </div>
      <SigmaContainer settings={sigmaSettings} style={{ height: "100vh" }} graph={graph}>
        <GraphEvents setHoveredCard={setHoveredCard} hoveredCard={hoveredCard}/>
      </SigmaContainer>
    </div>,
    document.body
  )
}

import _ from "lodash"
import { useMemo, useRef, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { useTranslates } from "../../../../common/useTranslates"
import { DashboardHttp } from "../../../../pages/DashboardPage/http"
import { gradeCategories } from "../../../../pages/DashboardPage/types/constants"
import {
  ColoringType,
  ContextType,
  GraphRefs,
  GraphRefType,
  LegendStyles,
  SerializedGraphData
} from "../../../../pages/DashboardPage/types/types"
import { IconButtonTemp } from "../../../UI/IconButtonTemp/IconButtonTemp"
import { RadioButton } from "../../../UI/RadioButton/RadioButton"
import { Legend } from "../../../system/components/Legend/Legend"
import styles from "./MapStyles.module.css"
import { CardsBlock } from "./components/CardsBlock/CardsBlock"
import { FullscreenGraph } from "./components/FullscreenGraph/FullscreemGraph"
import { Graph } from "./components/Graph/Graph"
import { GraphWidget } from "./components/GraphWidget/GraphWidget"
import { Instruction } from "./components/Instruction/Instruction"
import { DownloadIcon } from "./components/icons/DownloadIcon"
import { InfoIcon } from "./components/icons/InfoIcon"
import { mapTranslates as en } from "./translates/enTranslates"
import { mapTranslates as ru } from "./translates/ruTranslates"
import { MapTranslates } from "./translates/translates"
import { PreloadBar } from "../../../system/UX"

export function Map() {
  const { projectId } = useParams()
  const [downloadPending, setDownloadPending] = useState(false)
  const [mapToDisplay, setMapToDisplay] = useState<"all" | "clusters">("all")
  const [clustersMapToDisplay, setClustersMapToDisplay] = useState<ColoringType>("groups")
  const [cardsBlockToDisplay, setCardsBlockToDisplay] = useState<"cards" | "summary">("cards")
  const [blockToDisplayClusters, setBlockToDisplayClusters] = useState<
    "graphs" | "cards" | "summary"
  >("graphs")
  const [fullscreenGraph, setFullscreenGraph] = useState("")
  const [instruction, setInstruction] = useState(false)
  const [filterGroup, setFilterGroup] = useState<number[]>([])
  const [filterGrade, setFilterGrade] = useState<number[]>([])
  const [hoveredCard, setHoveredCard] = useState<string | null>(null)
  const { graphData, ideaGroups, clusters, cardsMap } = useOutletContext<ContextType>()
  const graphRefs = useRef<GraphRefs>({})
  const translates = useTranslates<MapTranslates>({ en: en, ru: ru })

  // TODO node size switch temporary
  const [nodeSize, setNodeSize] = useState<"absolute" | "percentile">("percentile")

  const memoGraphData = useMemo(() => {
    let memoGraphData: {
      [key: number]: { grades: SerializedGraphData; groups: SerializedGraphData }
    } = {}
    clusters?.forEach(cluster => {
      memoGraphData[cluster.id] = {
        grades: _.cloneDeep(graphData),
        groups: _.cloneDeep(graphData)
      }
    })
    return memoGraphData
  }, [graphData, clusters])

  // TODO translates
  // TODO All and Clusters component
  const radioOptions: { name: "all" | "clusters"; label: string }[] = useMemo(
    () => [
      { name: "all", label: translates.mapToDisplay.all },
      { name: "clusters", label: translates.mapToDisplay.clusters }
    ],
    [translates]
  )

  const radioOptionsClusters: { name: ColoringType; label: string }[] = useMemo(
    () => [
      { name: "groups", label: translates.clusterMapToDisplay.groups },
      { name: "grades", label: translates.clusterMapToDisplay.grades }
    ],
    [translates]
  )

  const radioOptionsCardsBlock: { name: "cards" | "summary"; label: string }[] = useMemo(
    () => [
      { name: "cards", label: translates.cardBlockToDisplay.cards },
      { name: "summary", label: translates.cardBlockToDisplay.summary }
    ],
    [translates]
  )

  const radioOptionsCardsBlockClusters: { name: "graphs" | "cards" | "summary"; label: string }[] =
    useMemo(
      () => [
        { name: "graphs", label: translates.cardBlockClustersToDisplay.graphs },
        { name: "cards", label: translates.cardBlockClustersToDisplay.cards },
        { name: "summary", label: translates.cardBlockClustersToDisplay.summary }
      ],
      [translates]
    )

  const mapLegendStyles: LegendStyles = useMemo(
    () => ({
      container: styles.graphLegend,
      checked: styles.graphLegendRowActive,
      item: styles.graphLegendRow,
      itemHover: styles.graphLegendRowHover,
      iconSize: "1rem"
    }),
    []
  )

  function resetFilters() {
    setFilterGrade([])
    setFilterGroup([])
  }

  async function handleDownloadXlsx() {
    setDownloadPending(true)
    const options = {
      suggestedName: `ideas_${projectId}`,
      types: [
        {
          description: "Excel Files",
          accept: {
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
          }
        }
      ]
    }

    try {
      const response = await DashboardHttp.downloadXlsx(Number(projectId))
      const blob = await response
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `ideas_${projectId}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
      setDownloadPending(false)
      setTimeout(() => window.URL.revokeObjectURL(url), 1000)
    } catch (error) {
      console.error("Error downloading file:", error)
      setDownloadPending(false)
    }
  }

  return (
    <div className={styles.mapContainer}>
      <div className={styles.mapButtonsContainer}>
        <div className={styles.mapButtonsRadio}>
          {clusters?.length !== 0 &&
            radioOptions.map(option => (
              <RadioButton
                key={option.name}
                name={option.name}
                label={option.label}
                checked={mapToDisplay === option.name}
                onClick={() => {
                  setMapToDisplay(option.name)
                  resetFilters()
                }}
              />
            ))}
          <IconButtonTemp onClick={() => setInstruction(true)}>
            <InfoIcon />
          </IconButtonTemp>
        </div>
        {/* {mapToDisplay === "clusters" && (
            <div className={styles.mapButtonsRadioClusters}>
              {clusters?.length !== 0 &&
                radioOptionsClusters.map(option => (
                  <RadioButton
                    key={option.name}
                    name={option.name}
                    label={option.label}
                    checked={clustersMapToDisplay === option.name}
                    onClick={() => {
                      setClustersMapToDisplay(option.name)
                    }}
                    CSSclasses={{
                      button: styles.mapRadioClustersButton
                    }}
                  />
                ))}
            </div>
          )} */}
        <div className={styles.mapButtonsRightContainer}>
          {mapToDisplay === "all" && (
            <div className={styles.mapButtonsRadioClusters}>
              {radioOptionsCardsBlock.map(option => (
                <RadioButton
                  key={option.name}
                  name={option.name}
                  label={option.label}
                  checked={cardsBlockToDisplay === option.name}
                  onClick={() => {
                    setCardsBlockToDisplay(option.name)
                  }}
                  CSSclasses={{
                    button: styles.mapRadioClustersButton
                  }}
                />
              ))}
            </div>
          )}
          {mapToDisplay === "clusters" && (
            <div className={styles.mapButtonsRadioClusters}>
              {clusters?.length !== 0 &&
                radioOptionsCardsBlockClusters.map(option => (
                  <RadioButton
                    key={option.name}
                    name={option.name}
                    label={option.label}
                    checked={blockToDisplayClusters === option.name}
                    onClick={() => {
                      setBlockToDisplayClusters(option.name)
                    }}
                    CSSclasses={{
                      button: styles.mapRadioClustersButton
                    }}
                  />
                ))}
            </div>
          )}
          <div className={styles.mapButtonsDownloadContainer}>
            <div className={styles.mapButtonsDownloadLoader}>
              {downloadPending && <PreloadBar size="1rem" />}
              <IconButtonTemp
                label=".xlsx"
                className={styles.mapButtonsDownload}
                onClick={handleDownloadXlsx}
              >
                <DownloadIcon />
              </IconButtonTemp>
            </div>
          </div>
        </div>
      </div>
      {mapToDisplay === "all" && (
        <div className={styles.mapLayoutAll}>
          <GraphWidget
            graphRefs={graphRefs}
            type="groups"
            legend={{
              styles: mapLegendStyles,
              filterSetter: setFilterGroup
            }}
            filters={{ groups: filterGroup, grades: filterGrade }}
            fullscreenControl={setFullscreenGraph}
            nodeSize={nodeSize}
            translates={translates.graphWidget}
            hoveredCard={hoveredCard}
            setHoveredCard={setHoveredCard}
          />
          <div className={styles.mapCardsBlockAll}>
            <CardsBlock
              cardsBlockToDisplay={cardsBlockToDisplay}
              translates={translates.cardBlock}
              filterGroup={filterGroup}
              hoveredCard={hoveredCard}
              setHoveredCard={setHoveredCard}
            />
          </div>
        </div>
      )}
      {mapToDisplay === "clusters" && (
        <div className={styles.mapLayoutClusters}>
          <div className={styles.mapClustersLegend}>
            <p>{translates.clusterLegend.groups}</p>
            {ideaGroups && (
              <Legend items={ideaGroups} setter={setFilterGroup} CSSstyles={mapLegendStyles} />
            )}
          </div>
          <div className={styles.mapClusters}>
            {clusters?.map(cluster => (
              <div key={cluster.id} className={styles.mapClustersColumn}>
                <p>{cluster.name}</p>
                {(blockToDisplayClusters === "cards" || blockToDisplayClusters === "summary") && (
                  <CardsBlock
                    filterGroup={filterGroup}
                    clusterId={cluster.id}
                    cardsBlockToDisplay={blockToDisplayClusters}
                    translates={translates.cardBlock}
                    hoveredCard={hoveredCard}
                    setHoveredCard={setHoveredCard}
                  />
                )}
                {blockToDisplayClusters === "graphs" && (
                  <>
                    {clustersMapToDisplay === "groups" && (
                      <Graph
                        clusterId={cluster.id}
                        graphData={memoGraphData[cluster.id]["groups"]}
                        coloring={"groups"}
                        sigmaClassName={styles.sigmaStyle}
                        fullscreenControl={() => setFullscreenGraph(`${cluster.id}-grades`)}
                        ref={ref => (graphRefs.current[`${cluster.id}-grades`] = ref)}
                        filterGroup={filterGroup}
                        filterGrade={filterGrade}
                        nodeSize={nodeSize}
                        hoveredCard={hoveredCard}
                        setHoveredCard={setHoveredCard}
                      />
                    )}
                    {clustersMapToDisplay === "grades" && (
                      <Graph
                        clusterId={cluster.id}
                        graphData={memoGraphData[cluster.id]["grades"]}
                        coloring={"grades"}
                        sigmaClassName={styles.sigmaStyle}
                        fullscreenControl={() => setFullscreenGraph(`${cluster.id}-groups`)}
                        ref={ref => (graphRefs.current[`${cluster.id}-groups`] = ref)}
                        filterGroup={filterGroup}
                        filterGrade={filterGrade}
                        nodeSize={nodeSize}
                        hoveredCard={hoveredCard}
                        setHoveredCard={setHoveredCard}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {fullscreenGraph && ideaGroups && graphRefs.current[fullscreenGraph] && (
        <FullscreenGraph
          fullscreenControl={() => setFullscreenGraph("")}
          ideaGroups={ideaGroups}
          gradeCategories={gradeCategories}
          legendStyles={mapLegendStyles}
          {...(graphRefs.current[fullscreenGraph] as GraphRefType)}
          translates={translates.fullscreenGraph}
          setHoveredCard={setHoveredCard}
          hoveredCard={hoveredCard}
        />
      )}
      <Instruction
        onClose={() => setInstruction(false)}
        active={instruction}
        translates={translates.instruction}
      />
    </div>
  )
}

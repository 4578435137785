import { DashboardNavUrl, GraphologyGradeCategory } from "./types"

export enum Layouts {
  forceAtlas = "forceAtlas2",
  another = "another"
}

export enum NodeCategoryColor {
  top = "#007F6D",
  local = "#00BAA0",
  bottom = "#E01E37",
  resist = "#F5B9C1",
  controversial = "#FFC001",
  indifferent = "#DBDBDB",
  unset = "silver"
}

export const NodeIdeaGroupColors = [
  "#FAC710",
  "#CEE741",
  "#F1BAA1",
  "#C90001",
  "#2797FF",
  "#87716D",
  "#f8d84b",
  "#EE82EE",
  "#7B68EE",
  "#9ACD32",
  "#008080",
  "#00CED1",
  "#D2691E"
]

export const CountryColors = [
   "#8dd3c7",
   "#bebada",
   "#80b1d3",
   "#fccde5",
   "#bc80bd",
   "#ffed6f"
]

export enum AnalyticsGradeColor {
  liked = "#007F6D",
  neutral = "#ADADAD",
  disliked = "#FF3A3A"
}

// TODO get name by label from translates
export const gradeCategories: GraphologyGradeCategory[] = [
  {
    id: 1,
    label: "top",
    name: ">= 0.8",
    color: NodeCategoryColor.top
  },
  {
    id: 2,
    label: "local",
    name: "0.6 ... 0.8",
    color: NodeCategoryColor.local
  },
  {
    id: 3,
    label: "bottom",
    name: "0.4...0.6",
    color: NodeCategoryColor.bottom
  },
  {
    id: 4,
    label: "resist",
    name: "0.2...0.4",
    color: NodeCategoryColor.resist
  },
  {
    id: 5,
    label: "controversial",
    name: "Точки разногласия",
    color: NodeCategoryColor.controversial
  },
  {
    id: 6,
    label: "indifferent",
    name: "< 0.2",
    color: NodeCategoryColor.indifferent
  }
]


export const navigationUrls: DashboardNavUrl[] = [
  "dashboard",
  "map",
  // "scatter",
  // "roles",
  // "analytics",
  // "results"
]

export enum OrgSize {
  SMALL = "1-9 employees",
  MEDIUM = "50-249 employees",
  LARGE = "250-499 employees",
  XLARGE = "500-1000 employees",
  XXLARGE = "1000+ employees",
}

export enum CardType {
  PEOPLE = "people",
  TECH = "tech",
  ORG = "org",
  OTHER = "other",
}
import { $authHost } from "../../axios"
import { UserRole } from "../../store/appReducer/appReducerTypes"
import {
  BaseClient,
  DashboardIdeaGroupIn,
  DashboardIdeaGroupOut,
  DashboardProjectCardIn,
  DashboardProjectGroupsSettings,
  DashboardProjectSettings
} from "./types"

export const getRawProjects = async () => {
  const response = await $authHost.get(`/card_sorting_admin/raw_form_data`)
  return response.data
}

export const deleteRawProject = async (raw_project_id: number) => {
  const response = await $authHost.delete(
    `/card_sorting_admin/raw_form_data?raw_project_id=${raw_project_id}`
  )
  return response.data
}

export const createFromRawProject = async (raw_project_id: number) => {
  const response = await $authHost.post(
    `/card_sorting_admin/raw_form_data?raw_project_id=${raw_project_id}`
  )
  return response.data
}

export const createAll = async () => {
  const response = await $authHost.post(`/card_sorting_admin/raw_form_data/create_all`)
  return response.data
}

export const getClientsInfo = async () => {
  const response = await $authHost.get("/card_sorting_admin/clients_info")
  return response.data
}

export const createNewClient = async (client: BaseClient) => {
  const response = await $authHost.post("/card_sorting_admin/create_client", client)
  return response.data
}

export const deleteClient = async (client_id: number) => {
  const response = await $authHost.delete(
    `/card_sorting_admin/delete_client?client_id=${client_id}`
  )
  return response.data
}

export const deleteUser = async (user_id: number) => {
  const response = await $authHost.delete(`/card_sorting_admin/delete_user?user_id=${user_id}`)
  return response.data
}

export const createUser = async (email: string, user_role: UserRole, client_id: number) => {
  const response = await $authHost.post(`/card_sorting_admin/create_user`, {
    email,
    user_role,
    client_id
  })
  return response.data
}

export const getDashboardProjects = async () => {
  const response = await $authHost.get(`/card_sorting_admin/dashboard`)
  return response.data
}

export const createDashboard = async (projectId: string) => {
  const response = await $authHost.post(`/card_sorting_admin/dashboard/${projectId}`)
  return response.data
}

export const deleteDashboard = async (projectId: string) => {
  const response = await $authHost.delete(`/card_sorting_admin/dashboard/${projectId}`)
  return response.data
}

export const getIdeaGroups = async (projectId: string) => {
  const response = await $authHost.get(`/card_sorting_admin/dashboard/${projectId}/groups`)
  return response.data
}

export const getDashboardCards = async (projectId: string) => {
  const response = await $authHost.get(`/card_sorting_admin/dashboard/${projectId}/cards`)
  return response.data
}

export const deleteIdeaGroup = async (projectId: string, groupId: number) => {
  const response = await $authHost.delete(
    `/card_sorting_admin/dashboard/${projectId}/groups/${groupId}`
  )
  return response.data
}

export const createIdeaGroup = async (
  projectId: string,
  data: DashboardIdeaGroupIn
): Promise<DashboardIdeaGroupOut> => {
  const response = await $authHost.post(`/card_sorting_admin/dashboard/${projectId}/groups`, data)
  return response.data
}

export const updateIdeaGroupNames = async (projectId: string, data: DashboardIdeaGroupIn[]) => {
  const response = await $authHost.patch(`/card_sorting_admin/dashboard/${projectId}/groups`, data)
  return response.data
}

export const updateCardGroup = async (projectId: string, data: DashboardProjectCardIn[]) => {
  const response = await $authHost.patch(`/card_sorting_admin/dashboard/${projectId}/cards`, data)
  return response.data
}

export const setGraphSettings = async (projectId: string, data: DashboardProjectSettings) => {
  const response = await $authHost.post(`/card_sorting_admin/dashboard/${projectId}/graph`, data)
  return response.data
}

export const getGroupsSettings = async (
  projectId: string
): Promise<DashboardProjectGroupsSettings> => {
  const response = await $authHost.get(
    `/card_sorting_admin/dashboard/${projectId}/groups/settings`
  )
  return response.data
}

export const setSplitGroupsSettings = async (
  projectId: string,
  data: DashboardProjectGroupsSettings
) => {
  const response = await $authHost.post(
    `/card_sorting_admin/dashboard/${projectId}/groups/settings`,
    data
  )
  return response.data
}

export const splitGroups = async (projectId: string) => {
  const response = await $authHost.get(`/card_sorting_admin/dashboard/${projectId}/groups/split`)
  return response.data
}

export const getNames = async (projectId: string) => {
  const response = await $authHost.get(`/card_sorting_admin/dashboard/${projectId}/groups/names`)
  return response.data
}

export const getSummariesOpenAI = async (projectId: string) => {
  const response = await $authHost.get(`/card_sorting_admin/dashboard/${projectId}/groups/summary`)
  return response.data
}


import Plot from "react-plotly.js"
import { GraphologyIdeaGroup } from "../../../../../../pages/DashboardPage/types/types"
import { useAppSelector } from "../../../../../../store/store"

interface DoughnutChartProps {
  ideaGroups: GraphologyIdeaGroup[]
  values: Map<number, number>
  sizeRem: number
}

export function SmallDoughnutChart(props: DoughnutChartProps) {
  const { ideaGroups, values, sizeRem } = props
  const remValue = useAppSelector(state => state.appReducer.remValue)

  return (
    ideaGroups && (
      <Plot
        data={[
          {
            values: Array.from(values.values()),
            type: "pie",
            hole: 0.55,
            direction: "clockwise",
            labels: ideaGroups.map(group => group.name),
            marker: {
              colors: ideaGroups.map(group => group.color)
            },
            showlegend: false,
            hoverinfo: "value+percent",
            textinfo: "value",
            textposition: "inside",
            // automargin: true,
            textfont: {
              family: "Arial",
              size: 10,
              color: "auto"
            }
          }
        ]}
        layout={{
          width: sizeRem * remValue,
          height: sizeRem * remValue,
          // margin: { t: 12, b: 12, l: 12, r: 12 },
          margin: { t: 6, b: 6, l: 6, r: 6 },
          paper_bgcolor: "#0000",
        }}
        config={{displaylogo: false, displayModeBar: false}}
      />
    )
  )
}

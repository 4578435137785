import { MapTranslates } from "./translates"

const mapToDisplayTranslates = {
  all: "General",
  clusters: "By divisions"
}

const mapGraphWidgetTranslates =  {
  groups: "Ideas map colored by common trends",
  grades: "Ideas map colored by voting results"
}

const clusterMapToDisplayTranslates = {
  groups: "By trends", 
  grades: "By voting"
}

const clusterLegendTranslates = {
  groups: "Trends:", 
  grades: "Voting results:"
}

const mapInstructionTranslates = {
  spot: {
    name: "Spot",
    description: "One of the responses/ideas/solutions/proposals",
  },
  size: {
    name: "Spot size",
    description: "Larger spots represent key ideas with high impact",
  },
  gravity: {
    name: "Attraction",
    description: "The closer the spots are to each other, the more similar the ideas are",
  },
  groupsColors: {
    name: "Topic colors",
    description: "Unified trends are marked with the same color",
  },
  gradesColors : {
    name: "Consensus colors",
    description: "Shows what can already be implemented and where further clarification is needed",
  }
}

const mapFullscreenGraphTranslates = {
  back: "Back",
  groups: "Ideas map colored by common trends",
  grades: "Ideas map colored by voting results"
}

const cardBlockToDisplayTranslates = {
  cards: "All Ideas",
  summary: "Key Points"
}

const cardBlockClustersToDisplay = {
  graphs: "Maps",
  cards: "All Ideas",
  summary: "Key Points"
}

const mapCardBlockTranslates = {
  disclaimer: "AI generated",
  keyPoints: "Key Points"
}

export const mapTranslates: MapTranslates = {
  mapToDisplay: mapToDisplayTranslates,
  clusterMapToDisplay: clusterMapToDisplayTranslates,
  graphWidget: mapGraphWidgetTranslates,
  instruction: mapInstructionTranslates,
  clusterLegend: clusterLegendTranslates,
  cardBlockToDisplay: cardBlockToDisplayTranslates,
  fullscreenGraph: mapFullscreenGraphTranslates,
  cardBlock: mapCardBlockTranslates,
  cardBlockClustersToDisplay: cardBlockClustersToDisplay
}

import { UndirectedGraph } from "graphology"
import { pagerank } from "graphology-metrics/centrality"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { getErrorUrl, templateCardTextHandler } from "../../common/utils"
import { DashboardFrame } from "../../project/modules/main/DashboardFrame/DashboardFrame"
import { PreloadBar } from "../../project/system/UX"
import { UserRole } from "../../store/appReducer/appReducerTypes"
import { useAppSelector } from "../../store/store"
import styles from "../CenterLayout.module.css"
import { DashboardHttp } from "./http"
import { navigationUrls, NodeIdeaGroupColors } from "./types/constants"
import {
  ClusterKeyPoints,
  DashboardCardEssentials,
  DashboardCardSortingInfoOut,
  DashboardCluster,
  DashboardCountry,
  DashboardInfo,
  DashboardNodeInfoInner,
  DashboardProjectSettings,
  DashboardScatterOut,
  DashboardUserOut,
  GraphologyGraphDataInner,
  GraphologyIdeaGroup,
  graphType,
  SerializedGraphData
} from "./types/types"
import { shuffleArray, UserPicList } from "./utils"
import { getPercentile } from "../../project/modules/main/Map/components/Graph/utils"

export function DashboardPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAppSelector(state => state.appReducer.user)
  const { projectId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [graphData, setGraphData] = useState<SerializedGraphData>()
  const [graphSettings, setGraphSettings] = useState<DashboardProjectSettings>()
  const [ideaGroups, setIdeaGroups] = useState<GraphologyIdeaGroup[]>()
  const [clusters, setClusters] = useState<DashboardCluster[]>()
  const [clustersKeyPoints, setClustersKeyPoints] = useState<ClusterKeyPoints[]>()
  const [countries, setCountries] = useState<DashboardCountry[]>()
  const [question, setQuestion] = useState<string>("")
  const [template, setTemplate] = useState<string[]>()
  const [userData, setUserData] = useState<DashboardUserOut[]>()
  const [info, setInfo] = useState<DashboardInfo>()
  const [scatterData, setScatterData] = useState<DashboardScatterOut>()
  const [cardSortingInfo, setCardSortingInfo] = useState<DashboardCardSortingInfoOut[]>()

  const pathPattern = useMemo(() => /^\/dashboard\/\d+\/?$/, [])

  const clusterOrder = [
    "Public Authorities",
    "Academia",
    "Small and Medium-sized Enterprises (SME)"
  ]

  useEffect(() => {
    if (pathPattern.test(location.pathname)) {
      return navigate(`/dashboard/${projectId}/dashboard`)
    }
  }, [location.pathname, navigate, pathPattern, projectId])

  useEffect(() => {
    // задаем настройки и данные для будущего графа
    setIsLoading(true)
    const fetchGraphData = DashboardHttp.getGraphData(Number(projectId))
      .then(data => {
        const graphData: GraphologyGraphDataInner<DashboardNodeInfoInner> = {
          attributes: {
            name: String(projectId)
          },
          options: {
            allowSelfLoops: false,
            multi: false,
            type: "undirected" as graphType
          },
          nodes: data.cards,
          edges: data.edges
        }
        data.settings && setGraphSettings(data.settings)
        data.template && setTemplate(data.template)
        const ideaGroupDispensableColors = [...NodeIdeaGroupColors]
        const ideaGroups = data.idea_groups.map(group => {
          return {
            ...group,
            color: ideaGroupDispensableColors.pop()
          }
        })
        setInfo(data.info)
        setIdeaGroups(ideaGroups)
        data.clusters &&
          setClusters(
            _.cloneDeep(data.clusters).sort(
              (a, b) => clusterOrder.indexOf(a.name) - clusterOrder.indexOf(b.name)
            )
          )
        data.clusters_key_points && setClustersKeyPoints(data.clusters_key_points)
        data.countries && setCountries(data.countries)
        setQuestion(data.question)
        if (graphData && graphData.nodes && graphData.nodes?.length !== 0) {
          const graph = UndirectedGraph.from(_.cloneDeep(graphData))

          // удаляем ноды без группы
          graph.forEachNode(node => {
            const groupId = graph.getNodeAttribute(node, "idea_group_id")
            !groupId && graph.dropNode(node)
          })

          // добавляем пейджранк
          pagerank.assign(graph, {
            getEdgeWeight: "weight",
            nodePagerankAttribute: "pagerank"
          })

          // определяем процентиль по pagerank
          const nodesAttributes = graph
            .nodes()
            .map(node => ({ key: node, pagerank: graph.getNodeAttribute(node, "pagerank") }))

          nodesAttributes.forEach(node =>
            graph.setNodeAttribute(
              node.key,
              "pagerankPercentile",
              getPercentile(nodesAttributes, node, "pagerank")
            )
          )

          setGraphData(graph.export() as SerializedGraphData)
        }
        setIsLoading(false)
      })
      .catch(error => navigate(getErrorUrl(error.message)))

    const fetchUserData = DashboardHttp.getUserData(Number(projectId))
      .then(data => {
        setUserData(data)
      })
      .catch(error => navigate(getErrorUrl(error.message)))

    Promise.all([fetchGraphData, fetchUserData]).then(() => {
      setIsLoading(false)
    })
  }, [projectId])

  const cardsMap = useMemo(() => {
    if (graphData?.nodes && ideaGroups) {
      const map: Map<string, DashboardCardEssentials> = new Map()
      graphData.nodes.forEach(card => {
        card.attributes &&
          map.set(card.key, {
            id: Number(card.key),
            text: templateCardTextHandler(card.attributes.text, template),
            pagerankPercentile: card.attributes.pagerankPercentile,
            controversial: card.attributes.controversial,
            ideaGroup: {
              id: card.attributes.idea_group_id,
              color: ideaGroups.find(group => group.id === card.attributes?.idea_group_id)?.color
            }
          })
      })
      return map
    }
  }, [graphData?.nodes, ideaGroups, template])

  const userMap = useMemo(() => {
    const map: Map<number, { pic: string; name: string }> = new Map()
    const userPicDisposable = shuffleArray([...UserPicList])
    if (userData) {
      userData.forEach(user => {
        const randomPic = userPicDisposable.pop()
        randomPic && map.set(user.id, { pic: randomPic, name: user.name })
      })
    }
    return map
  }, [userData])

  return (
    <div className={styles.dashboardBackground}>
      <div className={styles.centerLayout}>
        {isLoading && userMap ? (
          <PreloadBar isFullPage={true} />
        ) : (
          user && (
            <DashboardFrame
              question={question}
              user={{
                name: user.name,
                pic:
                  user.user_role === UserRole.SUPERADMIN
                    ? "super.png"
                    : userMap.get(user.id)?.pic ?? "default.png"
              }}
              navigationUrls={navigationUrls}
            >
              {/* TODO переделать контекст в редюсер */}
              <Outlet
                context={{
                  graphData,
                  ideaGroups,
                  clusters,
                  clustersKeyPoints,
                  countries,
                  graphSettings,
                  cardsMap,
                  userData,
                  userMap,
                  info,
                  scatterData,
                  setScatterData,
                  cardSortingInfo,
                  setCardSortingInfo,
                  template,
                  question
                }}
              />
            </DashboardFrame>
          )
        )}
      </div>
    </div>
  )
}

import { ControlsContainer, SigmaContainer } from "@react-sigma/core"
import "@react-sigma/core/lib/react-sigma.min.css"
import { NodeBorderProgram } from "@sigma/node-border"
import classNames from "classnames"
import { UndirectedGraph } from "graphology"
import { circular } from "graphology-layout"
import forceAtlas2 from "graphology-layout-forceatlas2"
import _ from "lodash"
import {
  Dispatch,
  MouseEventHandler,
  Ref,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"
import { useOutletContext } from "react-router-dom"
import { NodeCategoryColor } from "../../../../../../pages/DashboardPage/types/constants"
import {
  ColoringType,
  ContextType,
  DashboardCardClusterGradeInner,
  DashboardGradesCount,
  GraphRefType,
  SerializedGraphData
} from "../../../../../../pages/DashboardPage/types/types"
import { Layouts } from "../../../../../../pages/SuperAdminPage/types"
import { IconButtonTemp } from "../../../../../UI/IconButtonTemp/IconButtonTemp"
import { quadrantGroupTextColorsDemo } from "../../../ScatterChart/components/Scatter/DemoScatterConstants"
import { GraphEvents } from "../GraphEvents"
import drawHover from "../GraphHover/GraphHover"
import drawLabel from "../GraphLabel/GraphLabel"
import { FullScreenIcon } from "../icons/FullScreenIcon"
import styles from "./GraphStyles.module.css"
import { getControversial, getDarkerColor, getNodeAttributes, getPercentile } from "./utils"
import { zIndexOrdering } from "sigma/utils"

interface GraphProps {
  clusterId?: number
  coloring: ColoringType
  graphData: SerializedGraphData
  fullscreenControl?: MouseEventHandler
  setHoveredNodeLabel?: Dispatch<SetStateAction<string>>
  sigmaClassName?: string
  filterGroup: number[]
  filterGrade: number[]
  // TODO temporary
  nodeSize: "absolute" | "percentile"
  hoveredCard: string | null
  setHoveredCard: Dispatch<SetStateAction<string | null>>
}

function RefGraph(props: GraphProps, ref: Ref<GraphRefType>) {
  const {
    clusterId,
    coloring,
    graphData,
    fullscreenControl,
    sigmaClassName,
    filterGroup,
    filterGrade,
    nodeSize,
    hoveredCard,
    setHoveredCard
  } = props
  const { graphSettings, ideaGroups } = useOutletContext<ContextType>()

  const [noGrades, setNoGrades] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        sigmaSettings: sigmaSettings,
        graph: graph
      }
    },
    []
  )

  const sigmaSettings = useMemo(
    () => ({
      //см SigmaContainer -> Settings
      nodeProgramClasses: {
        border: NodeBorderProgram
      },
      defaultNodeType: "border",
      renderEdgeLabels: false,
      labelSize: 16,
      renderLabels: false,
      defaultEdgeColor: "#D6D6D6",
      minEdgeThickness: 1.2,
      defaultDrawNodeHover: drawHover,
      defaultDrawNodeLabel: drawLabel,
      zIndex: true
    }),
    []
  )

  const graph = useMemo(() => {
    if (graphData && graphData.nodes && graphData.nodes?.length !== 0 && graphSettings) {
      // создаем граф
      const graph = UndirectedGraph.from(graphData)

      // группы или идеи для легенды на фулскрине
      graph.setAttribute("type", coloring)

      // фильтруем ребра по мин. весу, прячем остальные
      // добавляем толщину ребра
      // TODO формула для толщины ребра
      const minWeight = graphSettings.general_settings.min_edge_weight
      graph.forEachEdge(edge => {
        const weight = graph.getEdgeAttribute(edge, "weight")
        if (minWeight > 1) {
          weight < minWeight
            ? graph.setEdgeAttribute(edge, "hidden", true)
            : graph.setEdgeAttribute(edge, "size", weight / 2)
        } else {
          graph.setEdgeAttribute(edge, "size", weight)
        }
      })

      // добавляем позиции по умолчанию и пейджранк
      circular.assign(graph)

      // получаем необходимые атрибуты для каждой ноды
      graph.forEachNode(node => {
        const attributes = graph.getNodeAttributes(node)
        // рассчитываем среднюю оценку и полярность по кластерам
        const grades = attributes.grades
        const clusterGrades: DashboardCardClusterGradeInner[] = []
        const totalGradesCount = grades.reduce(
          (accumulator: DashboardGradesCount, clusterGrades) => {
            accumulator.liked += clusterGrades.grades.liked
            accumulator.disliked += clusterGrades.grades.disliked
            accumulator.neutral += clusterGrades.grades.neutral
            return accumulator
          },
          { liked: 0, neutral: 0, disliked: 0 }
        )

        let totalGrades = 0

        grades.forEach(clusterGrade => {
          let totalClusterGrades = 0
          for (let count of Object.values(clusterGrade.grades)) {
            totalClusterGrades = totalClusterGrades + count
          }

          if (totalClusterGrades === 0) {
            const label = `${attributes.text} grades:`
            graph.setNodeAttribute(node, "label", label)
            return
          }

          totalGrades = totalGrades + totalClusterGrades

          const sumClusterGrade = clusterGrade.grades.liked - clusterGrade.grades.disliked

          clusterGrades.push({
            cluster: clusterGrade.cluster,
            averageClusterGrade: sumClusterGrade / totalClusterGrades,
            // для вычисления средней оценки ноды
            totalClusterGrades: totalClusterGrades
          })
        })

        graph.setNodeAttribute(node, "clusterGrades", clusterGrades)

        if (totalGrades === 0) {
          setNoGrades(true)
          const label = `${attributes.text} grades:`
          graph.setNodeAttribute(node, "label", label)
          return
        }

        // рассчитываем среднюю ноды как среднее взвешенное, где сумма весов = 1
        const averageGrade = clusterGrades.reduce((accumulator, grades) => {
          return (
            accumulator + (grades.averageClusterGrade * grades.totalClusterGrades) / totalGrades
          )
        }, 0)

        graph.setNodeAttribute(node, "averageGrade", averageGrade)
        graph.setNodeAttribute(node, "totalGradesCount", totalGradesCount)
        graph.setNodeAttribute(node, "controversialGrades", getControversial(totalGradesCount))

        const gradesToLabel = clusterId
          ? grades.find(clusterGrades => clusterGrades.cluster === clusterId)?.grades
          : totalGradesCount
        const label =
          `${attributes.text} grades: ${"\uD83D\uDC4D"} ${gradesToLabel?.liked} ` +
          `${"\u25EF"} ${gradesToLabel?.neutral} ${"\uD83D\uDC4E"} ${gradesToLabel?.disliked}`
        graph.setNodeAttribute(node, "label", label)
      })

      // определяем категорию по средним оценкам
      // определяем размер и категорию по pagerank
      const { min_node_size, node_size_factor } = graphSettings.general_settings
      const { mid_grade } = graphSettings.general_settings

      // сразу получаем темные цвета, если раскраска по оценкам
      // или карту цветов групп, если по группам
      if (coloring === "grades") {
        var darkerColorMap = new Map()
        Object.entries(NodeCategoryColor).forEach(([key, value]) => {
          darkerColorMap.set(key, getDarkerColor(value))
        })
      } else if (coloring === "groups") {
        var ideaGroupMap = new Map()
        ideaGroups?.forEach(group => {
          ideaGroupMap.set(group.id, {
            color: group.color,
            darkerColor: getDarkerColor(group.color)
          })
        })
      }

      const nodesAttributes = graph
        .nodes()
        .map(node => getNodeAttributes(graph, node, coloring, clusterId))

      nodesAttributes.forEach(node => {
        const averageGradePercentile = getPercentile(nodesAttributes, node, "averageGrade")

        if (clusterId) {
          // определяем процентиль по среднему кластера
          graph.updateNodeAttribute(node.key, "clusterGrades", clusterGrades =>
            clusterGrades?.map(grade =>
              grade.cluster === clusterId
                ? {
                    ...grade,
                    averageClusterGradePercentile: averageGradePercentile
                  }
                : grade
            )
          )
        } else {
          // определяем процентиль по среднему общему
          graph.setNodeAttribute(node.key, "averageGradePercentile", averageGradePercentile)
        }

        if (nodeSize === "percentile") {
          // определяем процентиль по pagerank
          // TODO убрать
          const pagerankPercentile = getPercentile(nodesAttributes, node, "pagerank")
          // устанавливаем размер
          const size = min_node_size + pagerankPercentile * node_size_factor
          graph.setNodeAttribute(node.key, "defaultSize", size)
          graph.setNodeAttribute(node.key, "size", size)
        } else if (nodeSize === "absolute") {
          // устанавливаем размер
          node.pagerank &&
            graph.setNodeAttribute(
              node.key,
              "size",
              min_node_size + node.pagerank * node_size_factor
            )
        }

        // graph.setNodeAttribute(node.key, "pagerankPercentile", pagerankPercentile)

        // определяем категорию оценки
        // const category = noGrades
        //   ? "unset"
        //   : getNodeCategory(node.controversialGrades, averageGradePercentile, mid_grade)

        // graph.setNodeAttribute(node.key, "category", category)

        // определяем группу ноды DEMO
        const quadrantGroup = (function () {
          if (node.x_offset !== undefined && node.y_offset !== undefined) {
            if (node.x_offset <= -0.2 && node.y_offset <= -0.2) {
              graph.setNodeAttribute(node.key, "quadrantGroup", 0)
              return 0
            } else if (node.x_offset >= 0.2 && node.y_offset <= -0.2) {
              graph.setNodeAttribute(node.key, "quadrantGroup", 2)
              return 2
            } else if (node.x_offset <= -0.2 && node.y_offset >= 0.2) {
              graph.setNodeAttribute(node.key, "quadrantGroup", 6)
              return 6
            } else if (node.x_offset >= 0.2 && node.y_offset >= 0.2) {
              graph.setNodeAttribute(node.key, "quadrantGroup", 8)
              return 8
            } else {
              graph.setNodeAttribute(node.key, "quadrantGroup", 4)
              return 4
            }
          }
        })()

        // определяем цвет ноды в зависимости от категорий оценки
        if (coloring === "grades" && quadrantGroup !== undefined) {
          const color = quadrantGroupTextColorsDemo.get(quadrantGroup)
          // const darkerColor = darkerColorMap.get(category)
          const darkerColor = getDarkerColor(color)
          graph.setNodeAttribute(node.key, "color", color)
          graph.setNodeAttribute(node.key, "defaultColor", color)
          graph.setNodeAttribute(node.key, "borderColor", darkerColor)
          graph.setNodeAttribute(node.key, "defaultBorderColor", darkerColor)
        }
        // определяем цвет ноды в зависимости от идейной группы
        // подсвечиваем только ноды, созданные польз-ми этого кластера, если есть кластер
        else if (coloring === "groups") {
          if (!clusterId || node.authorClusterId === clusterId) {
            const colors = ideaGroupMap.get(node.ideaGroupId)
            graph.setNodeAttribute(node.key, "color", colors.color)
            graph.setNodeAttribute(node.key, "defaultColor", colors.color)

            graph.setNodeAttribute(node.key, "borderColor", colors.darkerColor)
            graph.setNodeAttribute(node.key, "defaultBorderColor", colors.darkerColor)
          } else {
            graph.setNodeAttribute(node.key, "size", 5)
            graph.setNodeAttribute(node.key, "color", "silver")
            graph.setNodeAttribute(node.key, "defaultColor", "silver")
            graph.setNodeAttribute(node.key, "borderColor", "silver")
            graph.setNodeAttribute(node.key, "defaultBorderColor", "silver")
          }
        }
      })
      if (graphSettings.layout === Layouts.forceAtlas) {
        const forceAtlasConfig = _.mapKeys(graphSettings.force_atlas_config, (value, key) =>
          _.camelCase(key)
        )
        // определяем позиции нод по форс атлас
        forceAtlas2.assign(graph, {
          // iterations сильнее всего влияет на скорость отрисовки
          iterations: 100,
          getEdgeWeight: "weight",
          settings: forceAtlasConfig
        })
      }
      return graph
    }
  }, [graphData, graphSettings, coloring, clusterId, ideaGroups, nodeSize])

  useEffect(() => {
    if (graph) {
      // фильтрация
      const filterGroupSet = new Set(filterGroup)
      const filterGradeSet = new Set(filterGrade)
      // const highlightedNodes = []

      graph.forEachNode(node => {
        if (hoveredCard) {
          if (node === hoveredCard) {
            // graph.setNodeAttribute(node, "size", graph.getNodeAttribute(node, "defaultSize"))
            graph.setNodeAttribute(node, "color", graph.getNodeAttribute(node, "defaultColor"))
            // graph.setNodeAttribute(node, "size", 20)
            graph.setNodeAttribute(node, "color", "white")
            graph.setNodeAttribute(
              node,
              "borderColor",
              graph.getNodeAttribute(node, "defaultBorderColor")
            )
            // graph.setNodeAttribute(node, "borderColor", "#323232")
            // highlightedNodes.push(node)
            graph.setNodeAttribute(node, "highlighted", true)
            // graph.setNodeAttribute(node, "zIndex", 10000)
          }
          if (node !== hoveredCard && graph.neighbors(node).includes(hoveredCard)) {
            graph.setNodeAttribute(node, "highlighted", false)
            graph.setNodeAttribute(node, "size", graph.getNodeAttribute(node, "defaultSize"))
            // graph.setNodeAttribute(node, "size", 5)
            graph.setNodeAttribute(node, "color", graph.getNodeAttribute(node, "defaultColor"))
            graph.setNodeAttribute(
              node,
              "borderColor",
              graph.getNodeAttribute(node, "defaultBorderColor")
            )
          }
          if (node !== hoveredCard && !graph.neighbors(node).includes(hoveredCard)) {
            graph.setNodeAttribute(node, "highlighted", false)
            graph.setNodeAttribute(node, "color", "rgba(214, 214, 214, 0.88)")
            // graph.setNodeAttribute(node, "size", 5)
            graph.setNodeAttribute(node, "borderColor", "rgba(214, 214, 214, 0.88)")
          }
        } else {
          graph.setNodeAttribute(node, "highlighted", false)
          graph.setNodeAttribute(node, "size", graph.getNodeAttribute(node, "defaultSize"))
          graph.setNodeAttribute(node, "color", graph.getNodeAttribute(node, "defaultColor"))
          graph.setNodeAttribute(
            node,
            "borderColor",
            graph.getNodeAttribute(node, "defaultBorderColor")
          )
        }
      })

      graph.forEachEdge((edge, attributes, source, target) => {
        if (hoveredCard) {
          if (source === hoveredCard || target === hoveredCard) {
            graph.setEdgeAttribute(
              edge,
              "color",
              graph.getNodeAttribute(hoveredCard, "defaultColor")
            )
            graph.setEdgeAttribute(edge, "zIndex", 100000)
          } else {
            graph.setEdgeAttribute(edge, "color", "rgba(214, 214, 214, 0.88)")
          }
        } else {
          graph.setEdgeAttribute(edge, "color", "rgba(214, 214, 214, 1)")
        }
      })

      graph.forEachNode(node => {
        const attributes = graph.getNodeAttributes(node)
        const cluster = attributes.author?.cluster
        // группы
        const groupId = attributes.idea_group_id
        // категории оценки
        // const categoryLabel = attributes.category
        // const categoryId = gradeCategories?.find(
        //   category => category.label === categoryLabel
        // )?.id
        // DEMO группа грида
        const categoryId = attributes.quadrantGroup
        // const categoryId = gradeCategories?.find(
        //   category => category.label === categoryLabel
        // )?.id
        const visible =
          (clusterId ? (coloring === "groups" ? cluster === clusterId : true) : true) &&
          (filterGrade?.length === 0 ||
            (categoryId !== undefined && filterGradeSet?.has(categoryId))) &&
          (filterGroup?.length === 0 || (groupId && filterGroupSet?.has(groupId)))
        graph.setNodeAttribute(node, "hidden", !visible)
      })
    }
  }, [graph, filterGroup, filterGrade, clusterId, coloring, hoveredCard])

  return (
    <SigmaContainer
      settings={sigmaSettings}
      graph={graph}
      className={classNames(styles.sigmaContainer, sigmaClassName)}
    >
      <ControlsContainer position={"bottom-right"}>
        <IconButtonTemp className={styles.fullScreenControlsIcon} onClick={fullscreenControl}>
          <FullScreenIcon />
        </IconButtonTemp>
        {/* <IconButtonTemp label=".png" className={styles.mapButtonsDownload}>
          <DownloadIcon />
        </IconButtonTemp> */}
        {/* <IconButtonTemp style={{ padding: "0.3rem 0.15rem 0.3rem 0.3rem" }}>
          <DownloadIcon />
        </IconButtonTemp> */}
        {/* <GraphExportHTML /> */}
      </ControlsContainer>
      <GraphEvents setHoveredCard={setHoveredCard} hoveredCard={hoveredCard} />
    </SigmaContainer>
  )
}

export const Graph = forwardRef(RefGraph)

import React, { FC } from "react"

import styles from "./PreloaderStyles.module.css"
import classNames from "classnames"

interface PreloadBarProps {
  isFullPage?: boolean
  size?: string
}

export const PreloadBar: FC<PreloadBarProps> = props => {
  const { isFullPage = false, size } = props

  return (
    <div className={classNames(styles.circlePreloader, isFullPage && styles.fullPageCircle)}>
      <div className={styles.circle} style={size ? { width: size, height: size } : undefined} />
    </div>
  )
}

import { Dispatch, SetStateAction, useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { ContextType } from "../../../../../../pages/DashboardPage/types/types"
import { DashboardCard } from "../../../../../system/components/DashboardCard/DashboardCard"
import { MapCardBlockTranslates } from "../../translates/translates"
import { AIIcon } from "../icons/AIIcon"
import styles from "./CardsBlokStyles.module.css"

interface CardsBlockProps {
  cardsBlockToDisplay: "cards" | "summary"
  translates: MapCardBlockTranslates
  filterGroup: number[]
  clusterId?: number
  hoveredCard: string | null
  setHoveredCard: Dispatch<SetStateAction<string | null>>
}

export function CardsBlock(props: CardsBlockProps) {
  const { cardsBlockToDisplay, translates, filterGroup, clusterId, hoveredCard, setHoveredCard } =
    props
  const { cardsMap, graphData, ideaGroups, clustersKeyPoints } = useOutletContext<ContextType>()

  const filteredCards = useMemo(() => {
    let filteredCards
    if (filterGroup.length) {
      filteredCards = graphData.nodes.filter(card =>
        filterGroup.includes(card.attributes.idea_group_id)
      )
      if (hoveredCard) {
        const hoveredNode = graphData.nodes.find(card => card.key === hoveredCard)
        hoveredNode &&
          !filterGroup.includes(hoveredNode.attributes.idea_group_id) &&
          filteredCards.unshift(hoveredNode)
      }
    } else {
      filteredCards = graphData.nodes
    }

    if (clusterId) {
      filteredCards = filteredCards.filter(card => card.attributes.author.cluster === clusterId)
    }
    return filteredCards
  }, [filterGroup, graphData.nodes, clusterId, hoveredCard])

  const filteredGroups = useMemo(() => {
    if (filterGroup.length && ideaGroups) {
      return ideaGroups.filter(group => filterGroup.includes(group.id))
    } else {
      return ideaGroups
    }
  }, [filterGroup, ideaGroups])

  function parseSummaryText(summary: string) {
    return summary.split(";").map(
      (keyIdea, index) =>
        !!keyIdea.length && (
          <li className={styles.mapCardsBlockListItem} key={index}>
            {keyIdea.trim().charAt(0).toLocaleUpperCase() + keyIdea.trim().slice(1)}
          </li>
        )
    )
  }

  function parseKeyPoints() {
    const keyPoints = clustersKeyPoints?.find(clusterKeyPoints => clusterKeyPoints.cluster_id === clusterId)
      ?.key_points.split(" ")
    
    return keyPoints && <p><b>{keyPoints[0]}</b> {keyPoints.slice(1).join(" ")}</p>
  }

  return (
    <div className={styles.mapCardsBlock}>
      {cardsBlockToDisplay === "summary" && (
        <div className={styles.mapCardsBlockDisclaimer}>
          <p>{translates.disclaimer}</p>
          <AIIcon />
        </div>
      )}
      <div className={styles.mapCardsBlockContent}>
        <SimpleBar style={{ height: "100%", width: "100%" }}>
          <div className={styles.mapCardsBlockUnderScroll}>
            {cardsBlockToDisplay === "cards" &&
              filteredCards &&
              filteredCards.map(card => (
                <div
                  key={card.key}
                  onClick={() => {
                    hoveredCard === card.key ? setHoveredCard(null) : setHoveredCard(card.key)
                  }}
                >
                  <DashboardCard
                    card={cardsMap.get(String(card.key))!}
                    hovered={hoveredCard === card.key}
                  />
                </div>
              ))}
            {cardsBlockToDisplay === "summary" && filteredGroups && (
              <div className={styles.mapCardsBlockSummaries}>
                {!clusterId &&
                  filteredGroups.map(group => {
                    return (
                      group.summary && (
                        <div key={group.id} className={styles.mapCardsBlockSummary}>
                          <span style={{ color: group.color }}>{group.name}</span>
                          {parseSummaryText(group.summary)}
                        </div>
                      )
                    )
                  })}
                {clusterId && (
                  <>
                    {
                      !filterGroup.length && <div className={styles.mapCardsBlockSummary}>
                        <span>{translates.keyPoints}</span>
                        {parseKeyPoints()}
                      </div>
                    }

                    {filteredGroups.map(group => {
                      const cluster_summary = group.cluster_summary?.find(
                        clusterSummary => clusterSummary.cluster_id === clusterId
                      )
                      return (
                        cluster_summary?.summary && (
                          <div key={group.id} className={styles.mapCardsBlockSummary}>
                            <span style={{ color: group.color }}>{group.name}</span>
                            {parseSummaryText(cluster_summary.summary)}
                          </div>
                        )
                      )
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}

import { Language } from "../../store/appReducer/appReducerTypes"

export interface RawProject {
  id: number
  leader_email: string
  question: string
  date_submit: string
  users?: {
    user_email: string
    cluster: string
  }[]
}

export interface BaseClient {
  client_name: string
  language: Language
}

export interface ClientDTO extends BaseClient {
  client_id: number
  admins: {
    id: number
    email: string
    name: string
    link: string
  }[]
}

export interface DashboardProjectAdmin {
  id: number
  name: string
  email: string
}

export interface DashboardProjectsListOut {
  id: number
  name: string
  question?: string
  admins: DashboardProjectAdmin[]
  dashboard?: boolean
  settings?: DashboardProjectSettings
}

export interface DashboardIdeaGroupOut {
  id: number
  name: string
}

export interface DashboardIdeaGroupIn {
  id?: number
  name: string
  summary?: string
}

export interface DashboardProjectCardOut {
  id: number
  text: string
  idea_group: number | undefined
}

export interface DashboardProjectCardIn {
  id: number
  idea_group: number | undefined
}

export enum Layouts {
  forceAtlas = "forceAtlas2",
  another = "another",
}

export interface DashboardProjectGeneralSettings {
  min_edge_weight: number
  // min_grade: number
  mid_grade: number
  // max_grade: number
  min_pagerank: number
  // mid_pagerank: number
  max_pagerank: number
  min_node_size: number
  node_size_factor: number
}

export interface DashboardProjectGroupsSettings {
  groups: number | null
  resolution?: number
  cutoff?: number
  prompt?: string
}

export interface ForceAtlasConfig {
  adjust_sizes?: boolean
  barnes_hut_optimize?: boolean
  barnes_hut_theta?: number
  edge_weight_influence?: number
  gravity?: number
  lin_log_mode?: boolean
  outbound_attraction_distribution?: boolean
  scaling_ratio?: number
  slow_down?: number
  strong_gravity_mode?: boolean
}


export interface DashboardProjectSettings {
  groups_settings?: DashboardProjectGroupsSettings
  general_settings?: DashboardProjectGeneralSettings
  layout: Layouts
  force_atlas_config?: ForceAtlasConfig
}

import { DashboardFrameNavTranslates, DashboardFrameTranslates } from "./translates";

const dashboardFrameNavTranslates: DashboardFrameNavTranslates = {
  // analytics: "Analytics",
  map: "Map",
  // roles: "Roles and tasks",
  // results: "Results and suggestions",
  dashboard: "Overview",
  scatter: "Matrix"
}

export const dashboardFrameTranslates: DashboardFrameTranslates = {
  navigation: dashboardFrameNavTranslates,
  // userCounter: "participants"
}
